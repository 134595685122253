<template>
  <div
    class="aspect-h-1 aspect-w-1 relative w-full bg-contain bg-center bg-no-repeat md:aspect-h-[760] md:aspect-w-[1920] md:bg-[#a4cadd] md:bg-cover"
  >
    <CloudinaryImg
      :public-id="image.public_id"
      :steps="[768, 1024, 1280, 1536]"
      class="size-full object-cover"
    />
    <div :class="boxClass" class="flex items-center">
      <div class="absolute size-full" @click="navigate()" />
      <div
        v-if="teaser.title"
        class="max-w-[60%] p-2 text-left text-sm text-white sm:text-xl lg:w-[28rem] lg:text-3xl"
        :class="{
          'ml-4 lg:ml-24':
            teaser.box_alignment === 'LEFT' || !teaser.box_alignment,
          'mr-4 lg:mr-24': teaser.box_alignment === 'RIGHT',
          'hidden lg:block': !showMobileTeaser,
        }"
      >
        <div class="p-2 lg:p-4" :style="textBoxStyle">
          <h2 class="font-bold" v-html="teaser.title" />
          <div v-if="teaser.text" v-html="teaser.text" />
        </div>
        <div class="flex" :class="btnAlignment">
          <G7Button
            v-if="teaser.action_button"
            class="mt-2 p-1 lg:mb-10"
            :label="teaser.action_button.label"
            :to="teaser.action_button.context"
            color="orange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Dia } from "~~/types/diashow";

const props = defineProps<{
  teaser: Dia;
  isMobile: boolean;
}>();

const router = useRouter();
const showMobileTeaser = computed(
  () => router.currentRoute.value.name === "magazin"
);

const image = computed(() => {
  if (props.isMobile) {
    return props.teaser.image_mobile
      ? props.teaser.image_mobile
      : props.teaser.image;
  }
  return props.teaser.image;
});

const alignmentClasses = {
  LEFT: "justify-start",
  CENTER: "justify-center",
  RIGHT: "justify-end",
};

const btnAlignment = computed(() => {
  return props.teaser.action_button?.alignment
    ? alignmentClasses[props.teaser.action_button?.alignment]
    : "";
});

const boxClass = computed(() => {
  let str = "";
  if (props.teaser.box_alignment) {
    str += alignmentClasses[props.teaser.box_alignment];
  }
  if (image.value.context) {
    str += " cursor-pointer";
  }
  return str;
});

const textBoxStyle = computed(() => {
  let style = props.teaser.box_color
    ? `background-color: ${useHexToRgbA(
        props.teaser.box_color,
        props.teaser.box_transparency
      )}; `
    : "";
  style += props.teaser.text_color
    ? `color: ${props.teaser.text_color}`
    : "color: white;";

  return style;
});

function navigate() {
  if (image.value.context) {
    useRouter().push(image.value.context);
  }
}
</script>
